.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-display: auto;
  font-family: monospace, monospace;
  overflow: hidden;
  text-align: left;

  img {
    height: 44px;
  }

  &__mark {
    margin-right: 5px;
  }

  &__text {
    margin-left: 0.2rem;
    font-size: 1.125rem;
    white-space: nowrap;
    height: 1.5rem;
  }

  &__cursor {
    display: inline-block;
    width: 10px;
    height: 1rem;
    background: #fe5186;
    margin-left: 5px;
    border-radius: 1px;
    animation: cursor 1s infinite;
  }

  &__linescroller {
    :last-child:after {
      content: '';
      display: inline-block;
      position: relative;
      bottom: -0.1rem;
      width: 0.55rem;
      height: 1rem;
      background: #6cef14;
      margin-left: 0.5rem;
      border-radius: 1.5px;
      animation: cursor 1s infinite;
    }
  }

  &__line {
    height: 2rem;
    display: block;

    position: relative;
    animation: advance-line 0.5s ease-in-out 0s;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion: reduce) {
    &__cursor {
        animation: none;
    }
  }
}

@keyframes cursor {
  0% { opacity: 0; }
  40% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes advance-line {
  from { top: 0; }
  to { top: -2rem; }
}